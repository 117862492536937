@forward 'theme';
@forward 'tailwind';
html,
body {
  height: 100%;
}
body {
  margin: 0;
}
.broker-online-status-online {
  color: green;
}
.broker-online-status-offline {
  color: #cb8e1a;
}
.broker-online-status-dead {
  color: var(--warn-color);
}
.snackbar-info-style {
  background-color: orange;
  color: black;
}
.snackbar-info-style .mat-simple-snackbar-action  {
  color:var(--chip-color);
}
.snackbar-error-style {
  background-color: crimson;
  color: black;
}
.code {
  font-family: monospace;
  font-size: larger;
}
.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.mat-hint-text-ellipsis mat-hint {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
.truncate-lines-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}
.asset-card {
  width: 300px;
}
.list-style-material-icons-check {
  list-style-type: none;
  padding-inline-start: 8px;
}
.list-style-material-icons-check li {
    margin-top: 8px;
  }
.list-style-material-icons-check li:before {
      margin-right: 8px;
      content: 'check';
      font-family: 'Material Icons';
    }
.form-section-title {
  margin: 16px 3px;
  text-transform: uppercase;
  color: #373737;
  font-size: 14px;
  letter-spacing: 0.04em;
}
.property-grid-group-title {
  margin: 30px 3px 15px 3px;
  text-transform: uppercase;
  color: #373737;
  font-size: 14px;
  letter-spacing: 0.05em;
}
.mat-form-field-any-size-hint .mat-form-field-subscript-wrapper {
  position: unset !important;
}
// hrefs
.link {
  color: var(--link-color);
  text-decoration: none;
}
.link:hover,
  .link:focus {
    text-decoration: underline;
  }
.flex-even-sized {
  flex: 1 1 0;
}
.text-slate {
  color: rgba(0, 0, 0, 0.54);
}
.text-warn {
  color: var(--warn-color);
}
.bg-muted {
  background: rgba(0, 0, 0, 0.12);
}
.text-sm {
  font-size: 0.8em;
}
.mat-icon-\[80px\] {
  font-size: 80px !important;
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
  color: var(--icon-color);
}
.mat-icon-\[40px\] {
  font-size: 40px !important;
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  color: var(--icon-color);
}
.mat-icon-\[14px\] {
  font-size: 14px !important;
  width: 14px !important;
  height: 14px !important;
  line-height: 14px !important;
  color: var(--icon-color);
}
.mat-icon-\[10px\] {
  font-size: 10px !important;
  width: 10px !important;
  height: 10px !important;
  line-height: 10px !important;
  color: var(--icon-color);
}
.mat-icon-\[12px\] {
  font-size: 12px !important;
  width: 12px !important;
  height: 12px !important;
  line-height: 12px !important;
  color: var(--icon-color);
}
.mat-icon-\[28px\] {
  font-size: 28px !important;
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important;
  color: var(--icon-color);
}
.mat-card-header-text {
  overflow-wrap: break-word;
  overflow: hidden;
}
.mat-expansion-panel-flex-col .mat-expansion-panel-body {
  display: flex;
  flex-direction: column;
}
.mat-form-field-without-margin-bottom .mat-form-field-wrapper {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    padding-bottom: 0 !important;
  }
.max-two-lines-list-item .mat-list-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden !important;
}
.mat-drawer-inner-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden !important;
}
